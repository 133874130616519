<template>
  <div class="heroscreen">
      <img class="heroscreen-background" src="../images/code2.jpg" alt="image">
  </div>
</template>

<script>
  export default {
    name: 'HeroScreen'
  }
</script>

<style lang="scss" scoped>
  .heroscreen{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &-background{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: bottom center;
    }
  }
</style>
