<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <HeroScreen/>
    <HeroscreenIntro/>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import HeroscreenIntro from '@/components/HeroscreenIntro.vue'
import HeroScreen from '@/components/HeroScreen.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld,
    HeroScreen,
    HeroscreenIntro
  }
}
</script>
