<template>
  <div class="heroscreenIntro">
    <img class="heroscreenIntro-image" src="../images/logo.svg" alt="image">
    <h1>Dylan Rousseau</h1>
    <div class="heroscreenIntro-content">
      <span>Développeur web</span>
      <span>Full stack</span>
      <span>Junior</span>
    </div>
    <div class="heroscreenIntro-contact">
      <h2>Me contacter</h2>
      <span>Par téléphone</span>
      <a href="tel:+33648599199"><i class="fa fa-phone"></i></a><br>
      <span>Par mail</span>
      <a href="mailto:dylan.rousseau77@orange.fr"><i class="fa-solid fa-envelope"></i></a>
    </div>
    <div class="heroscreenIntro-info">
      <p>Site en cours de construction</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeroscreenIntro'
  }
</script>

<style lang="scss" scoped>
  .heroscreenIntro{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 30vw;
    background: rgba($light, .8);
    border-radius: 10px;

    > h1{
      position: relative;
      &::before{
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 40px;
        background-color: $dark;
      }
    }
    > h2{
      font-size: 1.5em;
    }
    &-image{
      position: relative;
      width: 40%;
      height: 40%;
    }

    &-content{
      margin: 20px 0;
      span{
        font-size: 1.5em;
        display: block;
        text-align: center;
        margin: 10px 0;
      }
    }
    &-contact{

      i{
        color: $dark;
        margin-left: 10px;
        transition: $trans;
        &:hover{
          color: $valid
        }
      }
    }
    &-info{
      margin: 20px 0;
    }
  }
  @media screen and (max-width: 830px) {
    .heroscreenIntro{
      width: 60vw;
    }
  }
</style>
